import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import Products from "./products";
import Acquiring from "../components/acquiring";
import Payments from "../payments";

import CorrectWindow from "../windows/correct.window";
import AgentsWindow from "../windows/agents.window";
import SupplierWindow from "../windows/supplier.window";
import BillingWindow from "../windows/billing.window";
import UrlPaymentsWindow from "../windows/url.payments.window";

import { NotFoundINN } from "../components/windows";

import styles from "../styles.module.css";

import { InputText } from "primereact/inputtext";
import Window from "components/windows";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

import { Select, LazySelect } from "components/forms/fields/select";

import mapDispatchToProps from "domain/actions/createReceipt.actions";

function CreateReceipt({ states, type, methods }) {
  const history = useHistory();
  const template = states.template;

  const [isPaySystem, setPaySystem] = useState(false);

  const [cancelErrorINN, setCancelErrorINN] = useState(false);
  const [showErrorINN, toggleErrorINN] = useState(false);
  const [show, onHide] = useState(false);
  const [cashboxList, setCashboxList] = useState([]);
  const [snoList, setListSno] = useState(null);
  const [paymentShow, setPaymentShow] = useState(false);
  const [urlPayment, setUrlPayment] = useState(null);

  const [loadPageSno, setLoadPageSno] = useState(false);

  const [displayCorrect, setDisplayCorrect] = useState(false);
  const [displayAgents, setDisplayAgents] = useState(false);
  const [displaySupplier, setDisplaySupplier] = useState(false);

  const dialogFuncMap = {
    displayCorrect: setDisplayCorrect,
    displayAgents: setDisplayAgents,
    displaySupplier: setDisplaySupplier,
  };

  useEffect(() => {
    methods.createExternalId();
    return () => methods.clearExternalId();
  }, [methods]);

  useEffect(() => {
    if (states.requisite?.unit) {
      const _filter = states.requisite.unit.filter(
        (unit) => unit.eng === "piece"
      );
      methods.updateFieldSchema("measurementUnit", _filter[0]);
    }
  }, [states.requisite]);

  useEffect(() => {
    methods.getOrganizations(1).then((r) => {
      if (r.data?.result?.total === 1) {
        methods.setOrganization(r.data.result.data[0]);
      }
      return r.data?.result?.total <= 0 && history.push("/main/organizations");
    });
  }, [methods, history]);

  const setInfoStorage = methods.setInfoStorage;

  useEffect(() => {
    if (!!states.organizations.length && states.requisite !== null) {
      setInfoStorage();
    }
  }, [states.organizations, states.requisite, setInfoStorage]);

  useEffect(() => {
    let org = states.receipt.org;
    let user = states.user;
    if (org) {
      if (!org.cashbox.length) {
        onHide(true);
        methods.onLoading(false);
      }

      let infoClient = {
        name: user ? `${user.lastName} ${user.firstName}` : "Администратор",
      };
      if (user) {
        if (!user.inn && !showErrorINN && !cancelErrorINN)
          return toggleErrorINN(true);

        infoClient.vatin = user ? user.inn : org.inn;
      }

      methods.setOperatorInfo(infoClient);
    }
  }, [
    states.receipt.org,
    states.user,
    states.requisite,
    methods,
    showErrorINN,
    cancelErrorINN,
  ]);

  useEffect(() => {
    if (!!states.receipt.org && !!states.receipt.org.cashbox) {
      let cashbox = states.receipt.org.cashbox;

      cashbox.forEach((i) => {
        try {
          let name = `${i.cashboxModel.model} зав/ном. - ${
            i.factoryNumber ? i.factoryNumber : "не указан"
          } (${i.status ? "Выкл" : "Вкл"})`;
          i.name = name;
        } catch (err) {
          i.name = `Не определенно`;
        }
      });

      setCashboxList(cashbox);

      let selectedCashboxId = localStorage.getItem("receiptCashboxId");

      if (!!selectedCashboxId) {
        let selectCashbox = cashbox.filter(
          (i) => String(i.id) === String(selectedCashboxId)
        );
        if (selectCashbox.length) {
          methods.setCashbox(selectCashbox[0]);
        } else {
          methods.setTaxationType(null);
          methods.setCashbox(null);
        }
      }

      return;
    }
    setCashboxList([]);
  }, [states.receipt.org, methods]);

  const setSnoFromOrganization = (org) => {
    if (!!org && !!org.snoAllCashboxes) {
      setListSno(org.snoAllCashboxes);

      return true;
    }

    return false;
  };

  const setDefaultTaxItem = () => {
    let org = states.receipt.org;
    if (
      org &&
      org.quickReceiptParams &&
      org.quickReceiptParams?.active &&
      states.requisite?.tax
    ) {
      const _filter = states.requisite.tax.filter(
        (tax) => tax.eng === org.quickReceiptParams.tax
      );
      if (_filter.length) methods.updateFieldSchema("tax", _filter[0]);
    }
  };

  useEffect(() => {
    let cashbox = states.receipt.cashbox;
    let org = states.receipt.org;

    methods.setTaxDefault();

    if (cashbox) {
      let taxes = cashbox.snoCashboxes.map((i) => i.sno);
      setListSno(taxes);

      if (cashbox?.cashboxModel?.model.toLowerCase() === "эмулятор") {
        let selectTax = taxes.filter(
          (i) => String(i.id) === String(cashbox.defaultSnoId)
        );
        if (selectTax.length) methods.setTaxationType(selectTax[0]);
      }

      let selectedTaxationTypeId = localStorage.getItem(
        "receiptTaxationTypeId"
      );

      if (!!selectedTaxationTypeId) {
        let selectTax = taxes.filter(
          (i) => String(i.id) === String(selectedTaxationTypeId)
        );
        methods.setTaxationType(selectTax[0]);
      } else {
        if (taxes.length) methods.setTaxationType(taxes[0]);
      }

      setDefaultTaxItem();

      return;
    }

    setDefaultTaxItem();

    if (setSnoFromOrganization(org)) return;

    setListSno([]);
  }, [
    states.receipt.cashbox,
    states.receipt.org,
    states.infoOrganization,
    methods,
  ]);

  useEffect(() => {
    let org = states.receipt.org;
    methods.setOnlinePay(false);
    setPaySystem(false);

    if (type === "payment") methods.setOnlinePay(true);

    if (!!org && !!org.organizationAcquiring) {
      setPaySystem(!!org.organizationAcquiring.length);
    }

    if (org && org.quickReceiptParams && org.quickReceiptParams?.active) {
      const params = org.quickReceiptParams;
      methods.setClientInfo({ emailOrPhone: params.emailOrPhone });

      methods.updateFieldSchema("name", params.name);
      methods.updateFieldSchema("name", params.name);
    } else {
      methods.updateFieldSchema("quantity", 1);
    }
  }, [states.receipt.org, methods]);

  useEffect(() => {
    if (states.infoOrganization) setLoadPageSno(true);
  }, [states.infoOrganization, loadPageSno]);

  useEffect(() => {
    if (loadPageSno) {
      if (!states.receipt.cashbox) {
        methods.setTaxationType(null);
      }
    }
  }, [states.receipt.cashbox, loadPageSno, methods]);

  useEffect(() => {
    let org = states.receipt.org;
    if (!show) {
      if (org) {
        if (!org.cashbox.length) methods.setOrganization(null);
      }
    }
  }, [show, states.receipt.org, methods]);

  const showWindows = (type) => {
    dialogFuncMap[`${type}`](true);
  };
  const hideWindows = (type) => {
    dialogFuncMap[`${type}`](false);
  };

  const onConfirm = (cb) => {
    methods.post().then((r) => {
      try {
        cb();
        if (r.data.success) {
          if (r.data.result?.url) {
            setUrlPayment(r.data.result?.url);
            setPaymentShow(true);
          } else {
            methods.clearReceipt();
            if (type === "payment") return history.push("/main/payments");
            return history.push("/main/receipts");
          }
        }
      } catch (err) {
        cb();
        return;
      }
    });
  };

  useEffect(() => {
    if (
      template &&
      template.receiptBody &&
      template.receiptBody?.items.length
    ) {
      methods.setItem(template.receiptBody.items[0]);
    }
  }, [template, methods]);

  useEffect(() => {
    return () => methods.clearTemplates();
  }, [methods]);

  useEffect(() => {
    if (cashboxList.length === 1) methods.setCashbox(cashboxList[0]);
  }, [cashboxList]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Продолжить"
          icon="pi pi-times"
          onClick={() => onHide(false)}
          className="p-button-text"
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <div>
        <div className={`p-col-12 p-d-flex p-flex-wrap p-jc-start p-mb-3`}>
          <LazySelect
            className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column p-jc-end"
            value={states.receipt.org}
            label="Выбор организации"
            optionLabel="name"
            options={
              states.receipt.org
                ? [states.receipt.org]
                : states.receipt.organizations
            }
            limit={7}
            onChange={(e) => {
              methods.setOrganization(e.value);
            }}
            getData={methods.getLazyOrganizations}
          />

          <Select
            className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column p-jc-end"
            name="cashbox"
            value={states.receipt.cashbox}
            label="Выбор кассы"
            options={cashboxList}
            onChange={(e) => methods.setCashbox(e)}
            showClear={true}
          />

          <Select
            className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column p-jc-end"
            value={states.receipt.taxationType}
            label="СНО"
            options={snoList ? snoList : []}
            onChange={(e) => methods.setTaxationType(e)}
            optionLabel="name"
          />

          <div className="p-d-none">
            <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
              Место расчета
            </label>
            <InputText
              value={states.receipt.paymentsPlace}
              onChange={(e) => methods.setPaymentsPlace(e.target.value)}
              className={`${styles.borderBottom}`}
            />
          </div>

          <Select
            className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column p-jc-end"
            value={states.receipt.type}
            label="Тип чека"
            options={states.requisite ? states.requisite.receiptType : []}
            onChange={(e) => methods.setType(e)}
            optionLabel="name"
          />

          <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
            <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
              ИНН покупателя
            </label>

            <input
              type="text"
              value={
                states.receipt.clientInfo ? states.receipt.clientInfo.vatin : ""
              }
              onChange={(e) => {
                let number = e.target.value.replace(/[\D]+/g, "");
                if (number.length <= 12)
                  methods.setClientInfo({
                    vatin: number,
                  });
              }}
              className={`${styles.borderBottom} ${styles.inputOpacity1} form-control p-inputtext p-component`}
            />
          </div>

          <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
            <label htmlFor="type-receipt" className={`${styles.font} p-pb-1`}>
              Покупатель
            </label>
            <InputText
              value={
                states.receipt.clientInfo ? states.receipt.clientInfo.name : ""
              }
              onChange={(e) => methods.setClientInfo({ name: e.target.value })}
              className={`${styles.borderBottom}`}
            />
          </div>

          <div className="p-col-12 p-sm-6 p-md-3 p-d-flex p-flex-column">
            <label className={`${styles.font} p-pb-1`}>Адрес покупателя</label>
            <InputText
              value={
                states.receipt.clientInfo
                  ? states.receipt.clientInfo.emailOrPhone
                  : ""
              }
              onChange={(e) =>
                methods.setClientInfo({ emailOrPhone: e.target.value })
              }
              className={`${styles.borderBottom}`}
              placeholder={
                type === "payment"
                  ? "Введите эл.почту"
                  : "Введите эл.почту или номер телефона"
              }
            />
          </div>

          <div className={`p-col-12 p-sm-6 p-md-3 p-flex-column p-d-flex `}>
            <div style={{ height: "24px" }}></div>
            <div
              className={`${
                states.receipt.cashbox?.needPrintOnPaper
                  ? "p-d-flex"
                  : "p-d-none"
              }`}
              style={{ paddingTop: "1rem" }}
            >
              <Checkbox
                inputId="print"
                onChange={(e) => methods.setElectronically(!e.checked)}
                checked={
                  states.receipt.cashbox?.needPrintOnPaper
                    ? !states.receipt.electronically
                    : false
                }
                className={`${styles.checkboxBox}`}
              />
              <label htmlFor="print" className={`p-ml-2`}>
                Печатать чек
              </label>
            </div>
          </div>

          <div className={`p-col-12 p-sm-6 p-md-3`}>
            <CorrectWindow
              show={displayCorrect}
              close={() => hideWindows("displayCorrect")}
            />
            <Button
              label="Основание коррекции"
              onClick={() => showWindows("displayCorrect")}
              className="c-btns--gradient"
              disabled={states.receipt.type ? states.receipt.type.id < 5 : true}
            />
          </div>

          <div className="p-col-12 p-sm-6 p-md-3 ">
            <SupplierWindow
              show={displaySupplier}
              close={() => hideWindows("displaySupplier")}
              onConfirms={methods.setSupplier}
              supplier={states.receipt.supplierInfo}
            />
            <Button
              label="Поставщик"
              onClick={() => showWindows("displaySupplier")}
              className="c-btns--gradient"
            />
          </div>

          <div className="p-col-12 p-sm-6 p-md-3 ">
            <AgentsWindow
              show={displayAgents}
              close={() => hideWindows("displayAgents")}
              onConfirms={methods.setAgents}
              agentInfo={states.receipt.agentInfo}
            />
            <Button
              label="Агент"
              onClick={() => showWindows("displayAgents")}
              className="c-btns--gradient"
            />
          </div>
        </div>

        <Products className="p-col-12" />

        {isPaySystem && (
          <Acquiring
            classCheckbox={styles.acquiring}
            className={styles.shadow}
          />
        )}

        <Payments onConfirm={onConfirm} requisite={states.requisite} />
      </div>

      <Window
        header="Предупреждение!"
        visible={show}
        modal
        style={{ width: "350px" }}
        footer={renderFooter()}
        onHide={() => onHide(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>У выбранной Организации нет подключенных касс!</span>
        </div>
      </Window>

      <NotFoundINN
        show={showErrorINN}
        onHide={() => {
          setCancelErrorINN(true);
          toggleErrorINN(false);
        }}
      />

      <BillingWindow />
      <UrlPaymentsWindow
        show={paymentShow}
        url={urlPayment}
        setShow={() => {
          setPaymentShow(false);
          setUrlPayment(null);
          if (type === "payment") return history.push("/main/payments");
          history.push("/main/receipts");
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    states: {
      user: state.users.current,
      requisite: state.requisites,
      organizations: state.organizations.organizations,
      infoOrganization: state.organizations.organizationById,
      receipt: state.createReceipt,
      receiptItems: state.createReceiptItems,
      wait: state.auth.isAuthenticated,
      template: state.receiptTemplates.current,
    },
    type: state.createReceiptItems.type,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReceipt);
