import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Label } from "components/based";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { addQuick } from "domain/actions/organizations/index";

function QuickReceiptParams(props) {
  const { disabled = false, requisite, organization, addQuick } = props;
  const [active, setActive] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [name, setName] = useState("");
  const [tax, setTax] = useState(null);
  const [paymentType, setPaymentType] = useState(null);
  const [acquires, setListAcquiring] = useState([]);

  useEffect(() => {
    if (!organization || !organization.organizationAcquiring) return;

    let acquiring = organization.organizationAcquiring.map((i) => {
      let acquiring = {
        name:
          i.acquiring.name + " " + (i.comment ?? "( Комментарий отсутствует )"),
        id: i.id,
      };

      return acquiring;
    });

    setListAcquiring(acquiring);
  }, [organization]);

  useEffect(() => {
    if (organization?.quickReceiptParams) {
      const quick = organization.quickReceiptParams;

      setActive(quick.active);
      setEmailOrPhone(quick.emailOrPhone);
      setName(quick.name);

      if (requisite) {
        setTax((prev) => {
          const _filter = requisite.tax.filter((i) => i.eng === quick.tax);
          return _filter.length ? _filter[0] : prev;
        });
      }

      if (acquires.length) {
        setPaymentType((prev) => {
          const _filter = acquires.filter(
            (i) => i.id === quick.organizationAcquiringId
          );
          return _filter.length ? _filter[0] : prev;
        });
      }
    }
  }, [organization, requisite, acquires]); // eslint-disable-line react-hooks/exhaustive-deps

  const confirm = () => {
    const data = {
      active: active,
      emailOrPhone: emailOrPhone ? emailOrPhone.trim() : null,
      name: name,
      tax: tax ? tax.eng : null,
      organizationAcquiringId: paymentType ? paymentType.id : null,
    };

    addQuick(data);
  };

  return (
    <div className={props.className}>
      <span className="block-label-title p-pb-4 title-silver p-col-12">
        Конфигурация "Быстрый чек"
      </span>

      <div className={`p-col-12 p-flex-column p-d-flex `}>
        <div className="p-d-flex">
          <Checkbox
            inputId="active"
            onChange={(e) => {
              setActive(e.checked);
            }}
            checked={active}
          />
          <label htmlFor="active" className={`p-ml-2`}>
            Использовать настройки
          </label>
        </div>
      </div>

      <div className="p-col-12 p-d-flex p-flex-column">
        <Label className="p-d-block" value="Адрес покупателя" />
        <InputText
          value={emailOrPhone}
          onChange={(e) => setEmailOrPhone(e.target.value)}
          className="c-border-bottom"
          placeholder="Введите эл.почту или номер телефона"
        />
      </div>

      <div className="p-col-12 p-fluid">
        <Label className="p-d-block" value="Товар" />
        <InputText
          value={name}
          className="c-border-bottom"
          onChange={(e) => setName(e.target.value)}
          disabled={disabled}
        />
      </div>

      <div className="p-col-12 p-fluid">
        <Label className="p-d-block" value="Налог.ставка" />
        <Dropdown
          value={tax}
          options={requisite ? requisite.tax : []}
          optionLabel="name"
          className="c-border-bottom"
          onChange={(e) => setTax(e.value)}
          disabled={disabled}
        />
      </div>

      <div className="p-col-12 p-d-flex p-flex-column">
        <Label className="p-d-block" value="Тип платежной системы" />
        <Dropdown
          value={paymentType}
          options={acquires}
          onChange={(e) => {
            setPaymentType(e.value);
          }}
          optionLabel="name"
          className="c-border-bottom"
        />
      </div>

      <div className="p-col-12 p-d-flex p-jc-end">
        <div className="p-col-12 p-md-6">
          <Button
            label="Применить"
            className="с-button-success"
            onClick={confirm}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    requisite: state.requisites,
    organization: state.orgCreate.select,
  };
};

export default connect(mapStateToProps, { addQuick })(QuickReceiptParams);
